// Utils to create constants
import getStaleTime from 'shared/utils/reactQuery/getStaleTime';

// Liability Types
export const ALL = 'ALL';
export const AUTO = 'AUTO';
export const MORTGAGE = 'MORTGAGE';
export const STUDENT = 'STUDENT';
export const PERSONAL = 'PERSONAL';
export const CREDITCARD = 'CREDITCARD';
export const HELOC = 'HELOC';
export const LOAN = 'LOAN';

// Valid Onboard Update States
export const ASSETS_UPDATED = 'ASSETS_UPDATED';
export const INCOME_ACCOUNT_CONNECTED = 'INCOME_ACCOUNT_CONNECTED';
export const INCOME_UPDATED = 'INCOME_UPDATED';
export const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
export const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
export const REVIEW_COMPLETED = 'REVIEW_COMPLETED';
export const SIGNUP_COMPLETED = 'SIGNUP_COMPLETED';

// Methodfi Status Constants
export const METHODFI_OPEN = 'METHODFI_OPEN';
export const METHODFI_SUCCESS = 'METHODFI_SUCCESS';
export const METHODFI_ERROR = 'METHODFI_ERROR';
export const METHODFI_USER_EXITED = 'METHODFI_USER_EXITED';

// Asset Provider Constants
export const ASSET_PROVIDER_ADVISOR = 'ADVISOR';
export const ASSET_PROVIDER_REFERRAL_PARTNER = 'REFERRAL_PARTNER';

// Dts related
export const DTS_COMPLETED = 'DTS_COMPLETED';
export const DTS_NOT_STARTED = 'DTS_NOT_STARTED';
export const DTS_IN_PROGRESS = 'DTS_IN_PROGRESS';

// Rules Engine MODES
export const FE_CHATGPT_ONLY = '0';
export const BE_NO_CHATGPT = '1';
export const BE_WITH_CHATGPT = '2';

// Default stale time for 5 minutes for react query
export const DEFAULT_STALE_TIME_FIVE_MINUTES = getStaleTime(5);
