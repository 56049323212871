import { Grid } from '@mui/material';

// Our Components
import OutstandingBalanceInput from 'components/Input/OutstandingBalanceInput';
import MonthlyPaymentInput from 'components/Input/MonthlyPaymentInput';
import RateInput from 'components/Input/RateInput';

function FinancialLiabilityForm({
	outstandingBalance,
	setOutstandingBalance,
	monthlyPayment,
	setMonthlyPayment,
	rate,
	setRate
}) {
	return (
		<>
			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<OutstandingBalanceInput
					outstandingBalance={outstandingBalance}
					setOutstandingBalance={setOutstandingBalance}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<MonthlyPaymentInput
					monthlyPayment={monthlyPayment}
					outstandingBalance={outstandingBalance}
					setMonthlyPayment={setMonthlyPayment}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={11}
				md={5}
				lg={4}
				sx={{ marginLeft: 1, marginRight: 1 }}
			>
				<RateInput rate={rate} setRate={setRate} />
			</Grid>
		</>
	);
}

export default FinancialLiabilityForm;
