import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Our Common props
import CommonPropTypes from 'shared/prop-types';

function CompanyLogo({ companyLogo, sx }) {
	if (!companyLogo) {
		return <Box sx={{ display: 'none' }} />;
	}

	return (
		<Box
			component="img"
			src={companyLogo}
			sx={{
				maxHeight: {
					xs: 250,
					sm: 350
				},
				maxWidth: {
					xs: 250,
					sm: 350
				},
				marginBottom: 2,
				...sx
			}}
		/>
	);
}

CompanyLogo.propTypes = {
	companyLogo: PropTypes.string.isRequired,
	sx: CommonPropTypes.sx
};

CompanyLogo.defaultProps = {
	sx: {}
};

export default CompanyLogo;
