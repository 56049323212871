import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';

// Our Components
import ExportableReportHeadingText from 'components/ExportableReport/Heading/ExportableReportHeadingText';

// Our Constants
import { CREDITCARD } from 'shared/constants';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

const TABLE_COLUMN_NAMES = [
	'Type',
	'Lender',
	'Balance',
	'Rate',
	'Monthly Payment'
];

function ExportableTable({ liabilities, liabilitiesTotalBalance }) {
	const formattedTotalLiabilityBalance = dollarFormatter.format(
		liabilitiesTotalBalance
	);

	const tableColumnsHeadings = useMemo(
		() =>
			TABLE_COLUMN_NAMES.map((tableHeading) => (
				<TableCell
					key={uuidv4()}
					sx={{
						fontSize: '0.75rem',
						lineHeight: '1rem'
					}}
				>
					{tableHeading}
				</TableCell>
			)),
		[]
	);

	const tableBodyRows = useMemo(
		() =>
			liabilities.map(
				({
					lender,
					outstandingBalance,
					interestRate,
					tradeLineType,
					monthlyMortgagePayment
				}) => {
					const formattedBalance =
						dollarFormatter.format(outstandingBalance);

					const formattedMonthlyPayment = () =>
						dollarFormatter.format(monthlyMortgagePayment);

					const formattedLoanType =
						tradeLineType === CREDITCARD ||
						tradeLineType === 'CREDIT_CARD'
							? 'Credit Card'
							: normalizeLoanType(tradeLineType);

					const currentCells = [
						formattedLoanType,
						lender,
						formattedBalance,
						`${interestRate}%`,
						formattedMonthlyPayment()
					];

					return (
						<TableRow key={uuidv4()}>
							{currentCells.map((currentCellData) => (
								<TableCell
									key={uuidv4()}
									sx={{
										fontSize: '0.75rem',
										lineHeight: '1rem'
									}}
								>
									{currentCellData}
								</TableCell>
							))}
						</TableRow>
					);
				}
			),
		[]
	);

	return (
		<>
			<ExportableReportHeadingText
				headingText={`Total Liabilities: ${formattedTotalLiabilityBalance}`}
			/>
			<TableContainer sx={{ marginBottom: 4 }}>
				<Table sx={{ flexGrow: 2 }} aria-label="Liability Table">
					<TableHead>
						<TableRow>{tableColumnsHeadings}</TableRow>
					</TableHead>
					<TableBody>{tableBodyRows}</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default ExportableTable;
