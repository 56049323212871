import { Grid, Typography } from '@mui/material';

// Our components
import Alert from 'components/Alert';
import ExportableReportPrint from 'components/ExportableReport/ExportReportPrint';
import LiabilityDataGrid from 'components/DataTable/LiabilityDataGrid';
import Loader from 'components/Loader/index';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetWalletOverview from 'hooks/client/wallet/useGetWalletOverview';
import useGetBurnDownChart from 'hooks/Burndown/queries/useGetBurnDownChart';
import useMutateSendStandardEmail from 'hooks/emails/useMutateSendStandardEmail';

// Our utils
import { dollarFormatter } from 'shared/utils';

function ProspectiveClientFinalPage() {
	const { isSuccess, data, isLoading } = useGetWalletOverview();
	const {
		isSuccess: isBurnDownChartSuccess,
		isLoading: isBurnDownChartLoading
	} = useGetBurnDownChart();

	const sendEmail = useMutateSendStandardEmail();

	if (isLoading || isBurnDownChartLoading) return <Loader />;

	if (isSuccess && isBurnDownChartSuccess) {
		const { firstName, lastName, email, advisor, liability } = data;

		const {
			firstName: advisorFirstName,
			lastName: advisorLastName,
			company
		} = advisor;

		const { total, liabilities } = liability;

		const formattedTotalLiabilities = dollarFormatter.format(total ?? 0);

		return (
			<>
				{sendEmail.isSuccess && <Alert variant="success" />}
				<Grid
					container
					item
					xs={12}
					sx={{
						paddingTop: { xs: 0, md: 4 },
						paddingLeft: { xs: 2, md: 10 },
						flexDirection: 'column',
						gap: 4,
						marginBottom: 4
					}}
				>
					<Typography variant="h1Gascogne">
						You've completed your onboarding
					</Typography>
					<Typography variant="body1">
						Reach out to {advisorFirstName} for a full report on how
						you can optimize your liabilities to grow your net
						worth.
					</Typography>

					<Grid container item xs={12} sx={{ gap: 2 }}>
						{!sendEmail.isLoading && (
							<TertiaryButton
								onClick={() =>
									sendEmail.mutate({
										emailSubject: `Prospective client ${firstName} ${lastName} for ${advisorFirstName} ${advisorLastName} from advisor firm ${company} has completed onboarding.`,
										emailBody: `${firstName} ${lastName} has completed onboarding and requested more information about optimizing their debt. You can download a full report of their liabilities by logging into your Sora account, selecting the client's name, and clicking "Export Report"`,
										prospectiveClientInfo: {
											firstName,
											lastName,
											email
										}
									})
								}
							>
								Request Report
							</TertiaryButton>
						)}
						{sendEmail.isLoading && <Loader />}

						<ExportableReportPrint />
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs={12}
					sx={{
						paddingLeft: { xs: 2, md: 10 },
						minHeight: 800
					}}
				>
					<Grid
						item
						xs={12}
						md={10}
						sx={{ marginBottom: { xs: 11, md: 6 } }}
					>
						<Typography
							variant="h2Gascogne"
							sx={{ marginBottom: 2 }}
						>
							Total Liabilities: {formattedTotalLiabilities}
						</Typography>

						<LiabilityDataGrid liabilities={liabilities} />
					</Grid>
				</Grid>
			</>
		);
	}

	return null;
}

export default ProspectiveClientFinalPage;
