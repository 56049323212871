import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material/index';

// Our Components
import CompanyLogo from 'components/Cards/Company/CompanyLogo';
import IntroPageFrame from 'components/Frame/IntroPageFrame';
import Loader from 'components/Loader/index';
import { PrimaryButton } from 'components/Button/Button';

// Hooks
import useGetAssetProvider from 'hooks/assetProvider/useGetAssetProvider';

// Our Routes
import { PROSPECTIVE_ADD_CLIENT_ROUTE } from 'routes';

function ProspectiveClientIntroPage() {
	const navigate = useNavigate();

	const { isError,isLoading, isSuccess, data } = useGetAssetProvider();

	if (isError) {
		return <div>Error</div>;
	}

	if (isLoading) {
		return <Loader />;
	}

	if (isSuccess) {
		const { companyLogo, firstName, lastName } = data.data;

		return (
			<IntroPageFrame>
				<Grid
					container
					item
					xs={12}
					sm={12}
					sx={{
						flexDirection: 'column',
						alignItems: 'center',
						gap: 4,
						paddingTop: 4,
						paddingBottom: 4
					}}
				>
					<CompanyLogo companyLogo={companyLogo} />

					<Box
						sx={{
							width: '80%',
							overflowWrap: 'break-word',
							textAlign: 'center'
						}}
					>
						<Typography variant="h2Gascogne">
							{firstName} {lastName} uses Sora Finance to onboard
							and optimize your liabilities.
						</Typography>
					</Box>

					<Box>
						<PrimaryButton
							onClick={() =>
								navigate(PROSPECTIVE_ADD_CLIENT_ROUTE)
							}
						>
							Begin Onboarding
						</PrimaryButton>
					</Box>
				</Grid>
			</IntroPageFrame>
		);
	}

	return <div>Error</div>;
}

export default ProspectiveClientIntroPage;
