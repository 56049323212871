import { useMutation, useQueryClient } from 'react-query';

// Our Hooks
import useMutateUpdateLiability from 'hooks/client/liabilities/mutations/useMutateUpdateLiability';

// Our Utils
import createDefaultAutoUpdatePayload from 'hooks/client/liabilities/defaultUpdateMutations/createDefaultAutoUpdate';
import createDefaultStudentUpdatePayload from 'hooks/client/liabilities/defaultUpdateMutations/createDefaultStudentUpdate';

// Our Constants
import { AUTO, CREDITCARD, MORTGAGE, STUDENT } from 'shared/constants';

// Our Query Keys
import { USER_LIABILITIES, WALLET_OVERVIEW_DATA } from 'shared/query-keys';

function useMutateAsyncUpdateAllLiabilities() {
	const updateLiability = useMutateUpdateLiability();

	const queryClient = useQueryClient();

	return useMutation(async (userLiabilityList) => {
		const filteredLiabilities = userLiabilityList.filter(
			({ tradeLineType }) =>
				tradeLineType !== MORTGAGE &&
				tradeLineType !== CREDITCARD &&
				tradeLineType !== 'CREDIT_CARD'
		);

		const liabilitiesWithApiCalls = filteredLiabilities.map(
			async (currentLiability) => {
				const { tradeLineType } = currentLiability;

				if (tradeLineType === AUTO) {
					updateLiability.mutateAsync({
						...currentLiability,
						...createDefaultAutoUpdatePayload(currentLiability)
					});
				} else if (tradeLineType === STUDENT) {
					updateLiability.mutateAsync({
						...currentLiability,
						...createDefaultStudentUpdatePayload(currentLiability)
					});
				} else {
					// Personal Liabilities
					updateLiability.mutateAsync(currentLiability);
				}
			}
		);

		const results = await Promise.allSettled(liabilitiesWithApiCalls);

		await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA, {
			refetchInactive: true
		});

		await queryClient.invalidateQueries(USER_LIABILITIES, {
			refetchInactive: true
		});

		return results;
	});
}

export default useMutateAsyncUpdateAllLiabilities;
