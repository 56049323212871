import { Grid } from '@mui/material';

// Our Components
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';

function ExpectedPayoffDate({
	approxPayoffDate,
	handleApproxPayoffDateChange
}) {
	return (
		<Grid
			item
			xs={12}
			sm={11}
			md={5}
			lg={4}
			sx={{ marginLeft: 1, marginRight: 1 }}
		>
			<StandardDatePicker
				label="Expected Payoff Date"
				helperText="Enter the approximate payoff date"
				onChange={handleApproxPayoffDateChange}
				value={approxPayoffDate}
				error={approxPayoffDate === ''}
			/>
		</Grid>
	);
}

export default ExpectedPayoffDate;
